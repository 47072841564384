import { apiClient } from "../ApiClient";

class PaymentService {
	merchantSessionKey = async () => {
		const { data } = await apiClient.getMerchantSessionKey();

		return data;
	};

	async getRepeatPrescriptionPaymentIntent(patientId) {
		const paymentIntentResponse = await apiClient.getRepeatPrescriptionPaymentIntent(patientId);

		return paymentIntentResponse
	};
}

export const paymentService = new PaymentService();
