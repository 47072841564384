import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { productService } from "../../services/ProductService";
import { clinicSearchTypes, ProductAvailability } from "../../enums";

// Define the sorting order based on ProductAvailability enum
const sortPriority = {
    [ProductAvailability.Available]: 0,
    [ProductAvailability.AvailableToOrder]: 1,
    [ProductAvailability.Unavailable]: 2,
    [ProductAvailability.Discontinued]: 3
};

export const getProductsForClinic = createAsyncThunk(
    "products/getProductsForClinic",

    async (payload, rejectWithValue) => {
        try {
            const products = await productService.getProductsForClinic(payload.query, payload.organisationId, payload.organisationEmployeeId, payload.status);

            products.sort((a, b) => {
                return sortPriority[a.availability] - sortPriority[b.availability]; //By default, products should be sorted by availability
            });

            return {
                status: payload.status,
                products
            };
        }
        catch (e) {
            rejectWithValue(null)
        }
    }
);

export const getProductsForPatient = createAsyncThunk(
    "products/getProductsForPatient",

    async (payload, rejectWithValue) => {
        try {
            const products = await productService.getProductsForPatient(payload.query);

            products.sort((a, b) => {
                return sortPriority[a.availability] - sortPriority[b.availability]; //By default, products should be sorted by availability
            });

            return { products };

        }
        catch (e) {
            console.log(e);
            rejectWithValue(null)
        }
    }
);

export const getAllTerpenes = createAsyncThunk(
    "terpenes/GetAll",

    async () => {
        const terpenes = await productService.getAllTerpenes();
        return {
            terpenes
        };
    }
);

export const getAllSuppliers = createAsyncThunk(
    "suppliers/GetAllForClinicUser",

    async (organisationId) => {
        const suppliers = await productService.getAllSuppliers(organisationId);
        return {
            suppliers
        };
    }
);

export const getAllSuppliersForPatient = createAsyncThunk(
    "suppliers/GetAllForPatient",

    async () => {
        const suppliers = await productService.getAllSuppliersForPatient();
        return {
            suppliers
        };
    }
);

export const getCurrentProductImages = createAsyncThunk(
    "products/GetAllProductImagesByIdForClinicUser",

    async ({ productId, organisationId }) => {
        const images = await productService.getCurrentProductImages(productId, organisationId);
        return {
            images
        };
    }
);

export const getCurrentProductDocs = createAsyncThunk(
    "products/GetAllProductDocumentsByIdForClinicUser",

    async ({ productId, organisationId }) => {
        const docs = await productService.getCurrentProductDocs(productId, organisationId);
        return {
            docs
        };
    }
);

export const getCurrentProductImagesForPatient = createAsyncThunk(
    "products/GetAllProductImagesByIdForPatient",

    async ({ productId }) => {
        const images = await productService.getCurrentProductImagesForPatient(productId);
        return {
            images
        };
    }
);

export const getCurrentProductDocsForPatient = createAsyncThunk(
    "products/GetAllProductDocumentsByIdForPatient",

    async ({ productId }) => {
        const docs = await productService.getCurrentProductDocsForPatient(productId);
        return {
            docs
        };
    }
);

export function productsToSearchList(data) {
    if (!data) data = {
        products: [],
    };

    const products = data.products.map(p => {
        return {
            text: p.name,
            type: clinicSearchTypes.productId,
            parameter: p.id
        }
    });

    return {
        products,
    }
}


const initialState = {
    clinicProducts: [],
    patientProducts: [],
    clinicTerpenes: [],
    clinicSuppliers: [],


    clinicProductsLoadingFlag: false,
    patientProductsLoadingFlag: false,
    clinicTerpenesLoadingFlag: false,
    clinicSuppliersLoadingFlag: false,
    productImagesLoadingFlag: false,
    productDocsLoadingFlag: false,

    loadingResultsFromClinicSearch: false,
    noResultsFromClinicSearch: false,

    loadingResultsFromPatientSearch: false,
    noResultsFromPatientSearch: false,
    searchListOptions: {
        products: [],
    },

    currentProductImages: [],
    currentProductDocs: [],

    currentProductImagesForPatient: [],
    currentProductDocsForPatient: [],

    clinicFilterOptions: [],
    clinicFilterNumberOfChecked: 0,
    clinicsFilteredProducts: [],

    patientFilterNumberOfChecked: 0,
    patientsFilteredProducts: [],

    patientSearchInputValue: "",
};

const productSlice = createSlice({
    name: "products",

    initialState,

    // sync actions
    reducers: {
        setInStateByKey(state, { payload }) {
            state[payload.key] = payload.data;
        },

        resetSearchResultsForClinicUser(state) {
            state.searchResultsForClinicUser = undefined
        },

        setClinicSearchSelectedValue(state, { payload }) {
            state.clinicSearchSelectedValue = payload;
        },

        setClinicSearchInputValue(state, { payload }) {
            state.clinicSearchInputValue = payload;
        },

        setPatientSearchInputValue(state, { payload }) {
            state.patientSearchInputValue = payload;
        },

        resetProductsForClinicUser(state) {
            state.clinicsProducts = initialState.clinicProducts;
        },

        resetProductsForPatient(state) {
            state.patientProducts = initialState.patientProducts;
        },

        setClinicFilterOptions(state, { payload }) {
            const { i, j, checked, filters } = payload;

            if (filters != undefined) {
                //set all filter options
                state.clinicFilterOptions = filters;
            }
            else {
                //update a single selected filter row's checked field
                state.clinicFilterOptions[i].values[j] = { ...state.clinicFilterOptions[i].values[j], checked: checked };
            }
        },

        setClinicFilterNumberOfChecked(state, { payload }) {
            const { value } = payload
            state.clinicFilterNumberOfChecked = value;
        },

        setClinicsFilteredProducts(state, { payload }) {
            const { value } = payload
            state.clinicsFilteredProducts = value
        },

        setPatientsFilteredProducts(state, { payload }) {
            const { value } = payload
            state.patientsFilteredProducts = value
        },

        setPatientFilterNumberOfChecked(state, { payload }) {
            const { value } = payload
            state.patientFilterNumberOfChecked = value;
        },
    },

    // async actions
    extraReducers: {
        //getProductsForClinic
        [getProductsForClinic.pending]: (state) => {
            state.clinicProductsLoadingFlag = true;
        },
        [getProductsForClinic.fulfilled]: (state, { payload }) => {
            const { products } = payload;
            state.clinicProducts = products;
            state.clinicProductsLoadingFlag = false;
        },
        [getProductsForClinic.rejected]: (state) => {
            state.clinicProductsLoadingFlag = false;
        },

        //getProductsForPatient
        [getProductsForPatient.pending]: (state) => {
            state.patientProductsLoadingFlag = true;
        },
        [getProductsForPatient.fulfilled]: (state, { payload }) => {
            const { products } = payload;
            state.patientProducts = products;
            state.patientProductsLoadingFlag = false;
        },
        [getProductsForPatient.rejected]: (state) => {
            state.patientProductsLoadingFlag = false;
        },

        //getAllTerpenes
        [getAllTerpenes.pending]: (state) => {
            state.clinicTerpenesLoadingFlag = true;
        },
        [getAllTerpenes.fulfilled]: (state, { payload }) => {
            const { terpenes } = payload;
            state.clinicTerpenes = terpenes;
            state.clinicTerpenesLoadingFlag = false;
        },
        [getAllTerpenes.rejected]: (state) => {
            state.clinicTerpenesLoadingFlag = false;
        },

        //getAllSuppliers
        [getAllSuppliers.pending]: (state) => {
            state.clinicSuppliersLoadingFlag = true;
        },
        [getAllSuppliers.fulfilled]: (state, { payload }) => {
            const { suppliers } = payload;
            state.clinicSuppliers = suppliers;
            state.clinicSuppliersLoadingFlag = false;
        },

        [getAllSuppliers.rejected]: (state) => {
            state.clinicSuppliersLoadingFlag = false;
        },

        //getAllSuppliersForPatient
        [getAllSuppliersForPatient.pending]: (state) => {
            state.clinicSuppliersLoadingFlag = true;
        },
        [getAllSuppliersForPatient.fulfilled]: (state, { payload }) => {
            const { suppliers } = payload;
            state.clinicSuppliers = suppliers;
            state.clinicSuppliersLoadingFlag = false;
        },

        [getAllSuppliersForPatient.rejected]: (state) => {
            state.clinicSuppliersLoadingFlag = false;
        },

        //getCurrentProductImages
        [getCurrentProductImages.pending]: (state) => {
            state.productImagesLoadingFlag = true;
        },
        [getCurrentProductImages.fulfilled]: (state, { payload }) => {
            const { images } = payload;
            state.currentProductImages = images;
            state.productImagesLoadingFlag = false;
        },
        [getCurrentProductImages.rejected]: (state) => {
            state.productImagesLoadingFlag = false;
        },

        //getCurrentProductDocs
        [getCurrentProductDocs.pending]: (state) => {
            state.productDocsLoadingFlag = true;
        },
        [getCurrentProductDocs.fulfilled]: (state, { payload }) => {
            const { docs } = payload;
            state.currentProductDocs = docs;
            state.productDocsLoadingFlag = false;
        },
        [getCurrentProductDocs.rejected]: (state) => {
            state.productDocsLoadingFlag = false;
        },

        //getCurrentProductImagesForPatient
        [getCurrentProductImagesForPatient.pending]: (state) => {
            state.productImagesLoadingFlag = true;
        },
        [getCurrentProductImagesForPatient.fulfilled]: (state, { payload }) => {
            const { images } = payload;
            state.currentProductImages = images;
            state.productImagesLoadingFlag = false;
        },
        [getCurrentProductImagesForPatient.rejected]: (state) => {
            state.productImagesLoadingFlag = false;
        },

        //getCurrentProductDocsForPatient
        [getCurrentProductDocsForPatient.pending]: (state) => {
            state.productDocsLoadingFlag = true;
        },
        [getCurrentProductDocsForPatient.fulfilled]: (state, { payload }) => {
            const { docs } = payload;
            state.currentProductDocs = docs;
            state.productDocsLoadingFlag = false;
        },
        [getCurrentProductDocsForPatient.rejected]: (state) => {
            state.productDocsLoadingFlag = false;
        },
    },
});

export default productSlice.reducer;

// export sync actions
export const {
    setInStateByKey,
    resetSearchResultsForClinicUser,
    setClinicSearchSelectedValue,
    setClinicSearchInputValue,
    setPatientSearchInputValue,
    resetProductsForClinicUser,
    resetProductsForPatient,
    setClinicFilterOptions,
    setClinicFilterNumberOfChecked,
    setClinicsFilteredProducts,
    setPatientsFilteredProducts,
    setPatientFilterNumberOfChecked,
} = productSlice.actions;

// selectors

export const selectClinicsProductsLoadingFlag = (state) =>
    state.products.clinicProductsLoadingFlag;

export const selectPatientProductsLoadingFlag = (state) =>
    state.products.patientProductsLoadingFlag;

export const selectClinicTerpenesLoadingFlag = (state) =>
    state.products.clinicTerpenesLoadingFlag;

export const selectClinicSuppliersLoadingFlag = (state) =>
    state.products.clinicSuppliersLoadingFlag;

export const selectClinicProducts = (state) =>
    state.products.clinicProducts;

export const selectPatientProducts = (state) =>
    state.products.patientProducts;

export const selectClinicTerpenes = (state) =>
    state.products.clinicTerpenes;

export const selectClinicSuppliers = (state) =>
    state.products.clinicSuppliers;

export const selectProductImagesLoadingFlag = (state) =>
    state.products.productImagesLoadingFlag;

export const selectProductDocsLoadingFlag = (state) =>
    state.products.productDocsLoadingFlag;

export const selectCurrentProductImages = (state) =>
    state.products.currentProductImages;

export const selectCurrentProductDocs = (state) =>
    state.products.currentProductDocs;

export const selectCurrentProductImagesForPatient = (state) =>
    state.products.currentProductImages;

export const selectCurrentProductDocsForPatient = (state) =>
    state.products.currentProductDocs;

export const selectFromProductSliceByKey = (key) => (state) =>
    state.products[key];

export const selectClinicFilterOptions = (state) =>
    state.products.clinicFilterOptions

export const selectClinicFilterNumberOfChecked = (state) =>
    state.products.clinicFilterNumberOfChecked;

export const selectClinicsFilteredProducts = (state) =>
    state.products.clinicsFilteredProducts;

export const selectPatientsFilteredProducts = (state) =>
    state.products.patientsFilteredProducts;

export const selectPatientFilterNumberOfChecked = (state) =>
    state.products.patientFilterNumberOfChecked;