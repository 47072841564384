/* eslint-disable no-useless-computed-key */
import {
  getCarerDetails, getOrganisationEmployeeDetails,
  getPatientDetails,
} from "./store/slices/accountSlice";

import fullPrescription from "./assets/images/upload_prescription_full_prescription.png";
import notBlurry from "./assets/images/upload_prescription_focus_not_blurry.png";
import goodLightning from "./assets/images/upload_prescription_good_lighting.png";

import doctor from "./assets/images/next_steps_doctor.svg";
import idv from "./assets/images/next_steps_idv_checked.svg";
import processed from "./assets/images/next_steps_processed.svg";
import { apiClient } from "./ApiClient";
import { patientConverter } from "./converters/PatientsConverter";

export const ShippingStatus = {
  NotShipped: 0,
  SentFromWarehouse: 1,
  ReceivedByPharmacy: 2,
  SentFromPharmacy: 3,
  ReceivedByCustomer: 4,
};

Object.freeze(ShippingStatus);

export const ProductType = {
  Oil: 0,
  Flower: 1,
  Cartridge: 2,
};

Object.freeze(ProductType);

export const ProductTypesMapper = {
  [ProductType.Oil]: "Oil",
  [ProductType.Flower]: "Flower",
  [ProductType.Cartridge]: "Cartridge",
}

Object.freeze(ProductTypesMapper);

export const ProductSubtype = {
  None: 0,
  Indica: 1,
  Sativa: 2,
  Hybrid: 3
};

Object.freeze(ProductSubtype);

export const ProductPhEur = {
  "None": 0,
  "5.1.4": 1,
  "5.1.8b": 2
};

Object.freeze(ProductPhEur);

export const ProductIrradiatedType = {
  "Non-Irradiated": 0,
  "Beta irradiated": 1,
  "Gamma irradiated": 2
};

Object.freeze(ProductPhEur);

export const StockCheckStatus = {
  AvailableInStock: 0,
  AvailableNotInStock: 1,
  UnavailableInStock: 2,
  UnavailableNotInStock: 3,
  Discontinued: 4
}

Object.freeze(StockCheckStatus);

export const productAvailabilityStatusMapper = {
  [StockCheckStatus.AvailableInStock]: "Available - in stock",
  [StockCheckStatus.AvailableNotInStock]: "Available - need to order",
  [StockCheckStatus.UnavailableInStock]: "Available - low stock",
  [StockCheckStatus.UnavailableNotInStock]: "Unavailable",
  [StockCheckStatus.Discontinued]: "Discontinued",
};

Object.freeze(productAvailabilityStatusMapper);


export const ProductSubtypesMapper = {
  [ProductSubtype.None]: "None",
  [ProductSubtype.Indica]: "Indica",
  [ProductSubtype.Sativa]: "Sativa",
  [ProductSubtype.Hybrid]: "Hybrid",
}

Object.freeze(ProductSubtypesMapper);

export const ProductPhEurMapper = {
  [ProductPhEur.None]: "None",
  [ProductPhEur["5.1.4"]]: "5.1.4",
  [ProductPhEur["5.1.8b"]]: "5.1.8b",
}

Object.freeze(ProductPhEurMapper);

export const ProductIrradiatedTypeMapper = {
  [ProductIrradiatedType["Non-Irradiated"]]: "Non-Irradiated",
  [ProductIrradiatedType["Beta irradiated"]]: "Beta Irradiated",
  [ProductIrradiatedType["Gamma irradiated"]]: "Gamma Irradiated",
}

Object.freeze(ProductPhEurMapper);

export const TerpeneOptions = {
  PERCENTAGE: 0,
  MAJORMINOR: 1,
}

Object.freeze(TerpeneOptions);

export const ProductAvailability = {
  Available: 0,
  Unavailable: 1,
  Discontinued: 2,
  AvailableToOrder: 3
}

Object.freeze(ProductAvailability);

export const ProductAvailabilityMapper = {
  [ProductAvailability.Available]: "Available",
  [ProductAvailability.Unavailable]: "Unavailable",
  [ProductAvailability.Discontinued]: "Discontinued",
  [ProductAvailability.AvailableToOrder]: "Available to order",
}

Object.freeze(ProductAvailabilityMapper);

export const OrderStatusFilter = {
  Recieved: 4,
  Pending: 5,
  Dispatched: 6,
  Expired: 7,
  ReadyToBeShipped: 8,
};

export const Filters = {
  ...ProductType,
  AllTypes: 3,
  ...OrderStatusFilter,
  AllStatuses: 9,
  AwaitingPaperPrescription: 10,
  PaperPrescriptionReceived: 11,
};

Object.freeze(Filters);

export const Sorts = {
  NewestToOldest: 0,
  OldestToNewest: 1,
  AlphabeticalASC: 2,
  AlphabeticalDESC: 3,
  NewestByPrescribedDate: 4,
};

Object.freeze(Sorts);

export const OrderState = {
  Active: 0,
  Unavailable: 1,
  Suspended: 2,
  Cancelled: 3,
  Void: 4,
  Archived: 5
}

Object.freeze(OrderState);

export const OrderStatuses = {
  OutOfStock: 0,
  OutOfStockAndPaper: 1,
  NotPaidAndNoPaper: 2,
  NotPaidAndPaper: 3,
  PaidAndNoPaper: 4,
  PaidAndPaper: 5,
  Shipped: 6,
  NoPaperAndPaymentMissed: 7,
  PaperAndPaymentMissed: 8,
  Void: 9,
  CompletedAndVoid: 10,
  NotApproved: 11,
  Complete: 12,
  Archived: 13,
  Cancelled: 14,
};

Object.freeze(OrderStatuses);

export const TransactionTypes = {
  Payment: 0,
  Refund: 1
}

Object.freeze(TransactionTypes)

export const ResubmitResponse = {
  AvailableInStock: 0,
  AvailableNotInStock: 1,
  UnavailableInStock: 2,
  UnavailableNotInStock: 3,
  Discontinued: 4,
};

Object.freeze(ResubmitResponse);

export const EmployeeType = {
  OrganisationPrescriber: 0,
  OrganisationAdmin: 1,
  OrganisationAdminPrescriber: 2,
};

Object.freeze(EmployeeType);

export const ProductUnit = {
  Mg: 1,
  G: 2,
  Ml: 3,

  getName(number) {
    switch (number) {
      case 1:
        return "ml";
      case 2:
        return "g";
      case 3:
        return "mg";

      default:
        return "g";
    }
  },
};

Object.freeze(ProductUnit);

export const Relationship = {
  Parent: 0,
  Guardian: 1,

  getName(number) {
    switch (number) {
      case 0:
        return "Parent";
      case 1:
        return "Guardian";
      default:
        return null;
    }
  },
};

export const UploadingTips = [
  {
    title: "Good lighting",
    text: "Take your photo in a bright light with no shadows.",
    image: goodLightning,
  },
  {
    title: "No blur",
    text: "Make sure your image is in focus and not blurry.",
    image: notBlurry,
  },
  {
    title: "Show full prescription",
    text: "Make sure your full prescription is in shot, right to the edges.",
    image: fullPrescription,
  },
];

Object.freeze(UploadingTips);

export const AfterRegistrationTipsWithPrescription = [
  {
    title: "Verify your account",
    text: "Verify your email so we can complete your account.",
    image: idv,
  },
  {
    title: "Processing your order",
    text: "We'll begin processing your prescription. This can take up to 48 hours to be processed.",
    image: doctor,
  },
  {
    title: "Track your order",
    text: "You can track and manage your order in your account at any time.",
    image: processed,
  },
];

Object.freeze(AfterRegistrationTipsWithPrescription);

export const AfterRegistrationTipsWithPrescriptionNewFlow = [
  {
    title: "Verify your account",
    text: "Verify your email so we can create your account",
    image: idv,
  },
  {
    title: "Processing your order",
    text: "If your Doctor has sent us a prescription, we will start processing it",
    image: doctor,
  },
  {
    title: "Track your order",
    text: "You can track and manage your order from your account at any time",
    image: processed,
  },
];

Object.freeze(AfterRegistrationTipsWithPrescriptionNewFlow);

export const AfterRegistrationWithoutPrescriptionTips = [
  {
    title: "Verify your account",
    text: "Verify your email so we can complete your account.",
    image: idv,
  },
  {
    title: "Upload a prescription",
    text: "When you're ready, simply upload a prescription.",
    image: doctor,
  },
  {
    title: "Track your order",
    text: "You can track and manage your order in your account at any time.",
    image: processed,
  },
];

Object.freeze(AfterRegistrationTipsWithPrescription);

export const UserRoleToFunctionMapper = Object.freeze({
  1: getPatientDetails,
  2: getCarerDetails,
  3: getOrganisationEmployeeDetails,
});

export const UserRoles = Object.freeze({
  Patient: 1,
  Carer: 2,
  OrganisationEmployee: 3,
});

export const toastTypes = Object.freeze({
  Error: 0,
  Success: 1,
});

export const clinicSearchTypes = Object.freeze({
  noFilter: 0,
  prescriptionId: 1,
  patientId: 2,
  productId: 3,
  OrganisationEmployeeId: 4,
});

export const clinicFilterTypes = Object.freeze({
  doctor: 0,
  status: 1,
  t21: 2,
  rewriteReason: 3,
  productAvaiablity: 4,
  productType: 5,
  productSubtype: 6,
  productT21: 7,
  productTerpenes: 8,
  productPhEur: 9,
  brandNames: 10,
  irradiated: 11,
});

export const headerPageTypes = Object.freeze({
  prescriptions: 0,
  patients: 1,
  formulary: 2,
  patientFormulary: 3,
});

export const ValidationErrorMessages = {
  PasswordLength: "Password must be at least 8 characters",
  PasswordCapitalLetter: "Password must contain at least one capital letter",
  PasswordLowercaseLetter: "Password must contain at least one lowercase letter",
  PasswordAtLeastOneNumber: "Password must contain at least one number",
  PasswordAtLeastOneSymbol: "Password must contain at least one symbol",
  PasswordShort: "Password cannot be longer than",
  PasswordContainsASpace: "Password cannot contain a space character",
};

Object.freeze(ValidationErrorMessages);

export const GetOrderToBePaidCallTypes = {
  GetUnprotectedOrderTobePaid: 5,
  GetProtectedOrderTobePaid: 1,
};

Object.freeze(GetOrderToBePaidCallTypes);

export const Emails = {
  Info: "info@lyphe.com",
}

Object.freeze(Emails);

export const OrganisationPrescriptionsTypes = {
  ImageOnly: "imageOnly",
  ToBeAssigned: "toBeAssigned",
  ReWriteRequests: "reWriteRequests",
  Active: "active",
  Complete: "complete",
}

Object.freeze(OrganisationPrescriptionsTypes);

export const ClinicPatientTypes = {
  Basic: "basic",
  Full: "full",
}

Object.freeze(ClinicPatientTypes);

export const OrganisationPrescriptionsTypesToApiCallMapper = {
  [OrganisationPrescriptionsTypes.ImageOnly]: apiClient.getProtoPrescriptions,
  [OrganisationPrescriptionsTypes.ToBeAssigned]: apiClient.getToBeAssignedPrescriptions,
  [OrganisationPrescriptionsTypes.ReWriteRequests]: apiClient.getAwaitingRewriteOrganisationPrescriptions,
  [OrganisationPrescriptionsTypes.Active]: apiClient.getActivePrescriptionsForOrganisation,
  [OrganisationPrescriptionsTypes.Complete]: apiClient.getPastPrescriptionsForOrganisation,
}

Object.freeze(OrganisationPrescriptionsTypesToApiCallMapper);

export const ClinicPrescriptionsExcludeCertainStatusesMapper = {
  [OrganisationPrescriptionsTypes.ImageOnly]: [],
  [OrganisationPrescriptionsTypes.ToBeAssigned]: "undefined",
  [OrganisationPrescriptionsTypes.ReWriteRequests]: "undefined",
  [OrganisationPrescriptionsTypes.Active]: "undefined",
  [OrganisationPrescriptionsTypes.Complete]: [OrderStatuses.OutOfStock, OrderStatuses.OutOfStockAndPaper, OrderStatuses.Cancelled, OrderStatuses.Archived, OrderStatuses.NotApproved, OrderStatuses.Shipped],
}

Object.freeze(ClinicPrescriptionsExcludeCertainStatusesMapper);

export const ClinicPatientTypesToApiCallMapper = {
  [ClinicPatientTypes.Full]: apiClient.getFullClinicPatients,
  [ClinicPatientTypes.Basic]: apiClient.getBasicClinicPatients,
}

Object.freeze(ClinicPatientTypesToApiCallMapper);

export const ClinicPatientTypesToPatientConverterMapper = {
  [ClinicPatientTypes.Full]: patientConverter.fullPatientsApiToClient,
  [ClinicPatientTypes.Basic]: patientConverter.tempPatientsApiToClient,
}

Object.freeze(ClinicPatientTypesToPatientConverterMapper);

export const SubscriptionTypes = {
  None: 0,
  AllPatient: 1,
  AccessSchemePatient: 2
}

Object.freeze(SubscriptionTypes);


export const genericSubscriptionTextMapper = {
  [SubscriptionTypes.None]: `Pay as you go`,
  [SubscriptionTypes.AllPatient]: `All patients`,
  [SubscriptionTypes.AccessSchemePatient]: `Access Scheme Patients`,
  undefined: "",
};

Object.freeze(genericSubscriptionTextMapper);

export const TableHeaders = {
  [OrganisationPrescriptionsTypes.ImageOnly]: () => {
    return [
      {
        text: 'image',
        column: 1,
      },
      {
        text: 'presc id',
        column: 1,
        left: true
      },
      {
        text: 'upload date',
        column: 1,
        left: true
      },
      {
        text: 'prescriber',
        column: 8,
        left: true
      },
      {
        text: 'action',
        column: 1,
      },
    ]
  },
  [OrganisationPrescriptionsTypes.ToBeAssigned]: () => {
    return [
      {
        text: 'image',
        column: 1,
      },
      {
        text: 'presc id',
        column: 1,
        left: true
      },
      {
        text: 'upload date',
        column: 1,
        left: true
      },
      {
        text: 'prescriber',
        column: 3,
        left: true
      },
      {
        text: 't21',
        column: 5,
        left: true,
      },
      {
        text: 'action',
        column: 1,
      },
    ]
  },
  [OrganisationPrescriptionsTypes.ReWriteRequests]: () => {
    return [
      {
        text: 'date requested',
        column: 1,
      },
      {
        text: 'patient',
        column: 1.5,
        left: true
      },
      {
        text: 'clinic patient id',
        column: 2,
        left: true
      },
      {
        text: 'prescription no',
        column: 2,
        left: true
      },
      {
        text: 'prescriber',
        column: 1.5,
        left: true,
      },
      {
        text: 're-write reason',
        column: 3,
        left: true,
      },
      {
        text: 'action',
        column: 1,
      },
    ]
  },
  [OrganisationPrescriptionsTypes.Active]: () => {
    return [
      {
        text: 'date uploaded',
        column: 1,
      },
      {
        text: 'patient',
        column: 2,
        left: true
      },
      {
        text: 'prescription no',
        column: 2,
        left: true
      },
      {
        text: 'order no',
        column: 2,
        left: true
      },
      {
        text: 'prescriber',
        column: 2,
        left: true,
      },
      {
        text: 'status',
        column: 2,
        left: true,
      },
      {
        text: 'action',
        column: 1,
      },
    ]
  },
  [OrganisationPrescriptionsTypes.Complete]: () => {
    return [
      {
        text: 'date uploaded',
        column: 1,
      },
      {
        text: 'patient',
        column: 2,
        left: true
      },
      {
        text: 'prescription no',
        column: 2,
        left: true
      },
      {
        text: 'order no',
        column: 2,
        left: true
      },
      {
        text: 'prescriber',
        column: 2,
        left: true,
      },
      {
        text: 'status',
        column: 2,
        left: true,
      },
      {
        text: 'action',
        column: 1,
      },
    ]
  },
  [ClinicPatientTypes.Full]: () => {
    return [
      {
        text: 'Created on',
        column: 2,
      },
      {
        text: 'clinic patient id',
        column: 2,
        left: true
      },
      {
        text: 'name',
        column: 2,
        left: true
      },
      {
        text: 'email',
        column: 2,
        left: true
      },
      {
        text: 'contact number',
        column: 3,
        left: true
      },
      {
        text: 'action',
        column: 1,
        left: true,
      },
    ]
  },
  [ClinicPatientTypes.Basic]: () => {
    return [
      {
        text: 'Created on',
        column: 2,
      },
      {
        text: 'clinic patient id',
        column: 2,
        left: true
      },
      {
        text: 'email',
        column: 3,
        left: true
      },
      {
        text: 'contact number',
        column: 3,
        left: true
      },
      {
        text: 'action',
        column: 2,
        left: true,
      },
    ]
  },
  ["products"]: () => {
    return [
      {
        text: 'status',
        column: 1,
        isSortable: true,
      },
      {
        text: 'product name',
        column: 2.5,
        left: true,
        isSortable: true,
      },
      {
        text: 'type',
        column: 0.5,
        left: true,
        isSortable: true,
      },
      {
        text: 'subtype',
        column: 0.5,
        left: true,
        isSortable: true,
      },
      {
        text: 'irradiated',
        column: 1,
        left: true,
        isSortable: true,
      },
      {
        text: 'ph. euro',
        column: 0.5,
        left: true,
        isSortable: true,
      },
      {
        text: 'thc',
        column: 0.5,
        left: true,
        isSortable: true,
      },
      {
        text: 'cbd',
        column: 0.5,
        left: true,
        isSortable: true,
      },
      {
        text: 'weight',
        column: 0.5,
        left: true,
        isSortable: true,
      },
      {
        text: 'retail price',
        column: 1,
        left: true,
        isSortable: true,
      },
      {
        text: 'access scheme incl. T21',
        tooltip: true,
        column: 1,
        left: true,
        isSortable: true,
      },
      {
        text: 'dormant terpenes',
        column: 2,
        left: true,
        isSortable: false,
      },
      {
        text: 't21',
        column: 0.5,
        left: true,
        isSortable: true,
      },
    ]
  },
  ["patientProducts"]: () => {
    return [
      {
        text: 'availability',
        tooltip: true,
        column: 2,
        isSortable: true,
      },
      {
        text: 'name',
        column: 3,
        left: true,
        isSortable: true,
      },
      {
        text: 'type',
        column: 1,
        left: true,
        isSortable: true,
      },
      {
        text: 'irradiated',
        tooltip: true,
        column: 2,
        left: true,
        isSortable: true,
      },
      {
        text: 'thc',
        column: 1,
        left: true,
        isSortable: true,
      },
      {
        text: 'cbd',
        column: 1,
        left: true,
        isSortable: true,
      },
      {
        text: 'total price',
        column: 2,
        left: true,
        isSortable: true,
      },
    ]
  },
}
