/* eslint-disable default-case */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    FilterIcon,
} from "../../assets/icons";
import { ProductAvailabilityMapper, ProductIrradiatedTypeMapper, ProductTypesMapper, clinicFilterTypes } from "../../enums";
import { callMapper } from "../../util/util";
import {
    selectPatientProducts,
    selectClinicFilterOptions, selectPatientFilterNumberOfChecked,
    setClinicFilterOptions, setPatientFilterNumberOfChecked, setPatientsFilteredProducts
} from "../../store/slices/productSlice";

function PatientProductsFilter(props) {
    const [showPopup, setShowPopup] = useState(false);
    const dispatch = useDispatch();

    const patientsProducts = useSelector(selectPatientProducts);

    const filterOptions = useSelector(selectClinicFilterOptions);
    const filterNumberOfChecked = useSelector(selectPatientFilterNumberOfChecked);

    const brandNames = ["Adven", "Althea", "Aurora", "Vida", "Bedrocan", "BOL Pharma", "Canopy", "Cellen", "Curaleaf", "Four20", "Green Karat", "Grow", "Hexacan", "Khiron", "Lot420", "Lumir", "Medcan Isando", "Noidecs", "Therismos", "Tilray", "Viola"];

    //populate filter options
    useEffect(() => {
        if (filterNumberOfChecked == 0) {
            dispatch(setClinicFilterOptions({ filters: createFilterOptions() }));
        }
    }, [patientsProducts])

    //apply filter if number of currently checked filers > 0
    useEffect(() => {
        if (filterNumberOfChecked > 0) {
            filterProducts();
        }
    }, [patientsProducts, filterNumberOfChecked])

    const filterProducts = () => {
        let filters = getSelectedFilters();
        let filteredProds = patientsProducts;
        //for filtering, we iterate over all the filters, checking filter type each time, and reduce the resulting array each time according to the type of filter

        filters.forEach((filter) => {
            if (filter.values.length != 0) {
                switch (filter.type) {

                    case clinicFilterTypes.productAvaiablity:
                        filteredProds = filteredProds.reduce((acc, curr) => {

                            let isNodeSatisfied = false;
                            isNodeSatisfied = isNodeSatisfied || (filter.values.indexOf(callMapper(ProductAvailabilityMapper, curr.availability)) > -1);

                            if (isNodeSatisfied) {
                                acc.push(curr)
                            }

                            return acc;
                        }, []);
                        break;

                    case clinicFilterTypes.productType:
                        filteredProds = filteredProds.reduce((acc, curr) => {

                            let isNodeSatisfied = false;
                            isNodeSatisfied = isNodeSatisfied || (filter.values.indexOf(callMapper(ProductTypesMapper, curr.type)) > -1);

                            if (isNodeSatisfied) {
                                acc.push(curr)
                            }

                            return acc;
                        }, []);
                        break;


                    case clinicFilterTypes.irradiated:
                        filteredProds = filteredProds.reduce((acc, curr) => {

                            let isNodeSatisfied = false;
                            isNodeSatisfied = isNodeSatisfied || (filter.values.indexOf(callMapper(ProductIrradiatedTypeMapper, curr.irradiatedType)) > -1);

                            if (isNodeSatisfied) {
                                acc.push(curr)
                            }

                            return acc;
                        }, []);
                        break;

                    case clinicFilterTypes.brandNames:

                        filteredProds = filteredProds.reduce((acc, curr) => {
                            let isNodeSatisfied = false;

                            isNodeSatisfied = isNodeSatisfied || filter.values.some(filterName => curr.name.includes(filterName));

                            if (isNodeSatisfied) {
                                acc.push(curr)
                            }

                            return acc;
                        }, []);
                        break;
                }
            }
        })

        dispatch(setPatientsFilteredProducts({ value: filteredProds }))
    }

    const getSelectedFilters = () => {
        let filters = [
            { type: clinicFilterTypes.productAvaiablity, values: [] },
            { type: clinicFilterTypes.productType, values: [] },
            { type: clinicFilterTypes.irradiated, values: [] },
            { type: clinicFilterTypes.brandNames, values: [] },
        ];
        filterOptions.forEach(function (option) {
            let type = option.type;
            option.values.forEach(function (row) {
                if (row.checked) {
                    switch (type) {
                        case clinicFilterTypes.productAvaiablity:
                            filters[0].values.push(row.value)
                            break;
                        case clinicFilterTypes.productType:
                            filters[1].values.push(row.value)
                            break;
                        case clinicFilterTypes.irradiated:
                            filters[2].values.push(row.value)
                            break;
                        case clinicFilterTypes.brandNames:
                            filters[3].values.push(row.value)
                            break;
                    }
                }
            })
        })

        return filters;
    }

    const handleOpen = () => {
        setShowPopup(!showPopup);
    }

    const handleClose = (e) => {
        //this is so the contents of the div (checkboxes) don't close the options menu when checked
        if (!e.currentTarget.contains(e.relatedTarget)) {
            setShowPopup(false);
        }
    }

    const createFilterOptions = () => {
        let options = [];

        //Availability
        options.push(
            {
                type: clinicFilterTypes.productAvaiablity,
                values: ['Available', 'Unavailable', 'Discontinued', 'Available to order']
                    .map(val => {
                        return { value: val };
                    })
            }
        )

        //Type
        options.push(
            {
                type: clinicFilterTypes.productType,
                values: ['Flower', 'Oil', 'Cartridge']
                    .map(val => {
                        return { value: val };
                    })
            }
        )

        //irradiated
        options.push(
            {
                type: clinicFilterTypes.irradiated,
                values: ['Non-Irradiated', 'Beta Irradiated', 'Gamma Irradiated']
                    .map(val => {
                        return { value: val };
                    })
            }
        )

        //brandName
        options.push({
            type: clinicFilterTypes.brandNames,
            values: brandNames
                .sort()
                .map(val => {
                    return { value: val };
                })
        });

        return options;
    }

    const handleCheckboxChange = (e, i, j) => {
        dispatch(setClinicFilterOptions({ checked: e.target.checked, i: i, j: j }));

        //count the amount of checked checkboxes and store it
        dispatch(setPatientFilterNumberOfChecked({
            value: document.querySelectorAll('#divFilterCheckboxes input[type="checkbox"]:checked').length,
        }));
    }

    const handleFilterClearByType = (type) => {
        let numOfChanges = 0;
        filterOptions.forEach((option, i) => {
            if (option.type == type) {
                option.values.forEach((value, j) => {
                    if (value.checked) {
                        dispatch(setClinicFilterOptions({ checked: false, i: i, j: j }));
                        numOfChanges++;
                    }
                });
                dispatch(setPatientFilterNumberOfChecked({
                    value: filterNumberOfChecked - numOfChanges,
                }));
            }
        });
    }

    function truncateStr(str, num) {
        if (str.length <= num) {
            return str;
        } else {
            str = str.slice(0, num);
            return str + "...";
        }
    }

    const renderOptions = (options, onClick = () => null) => {
        return <div>
            {
                options.map((option, i) => {
                    return <div key={"option" + i}>
                        <div className="clinic_filter__options__section_title">
                            {callMapper(filterTypesToStringMapper, option.type)()}
                            <hr />
                        </div>
                        {
                            option.values.map((row, j) => {
                                return <div key={"row" + j}>
                                    <div id="divFilterCheckboxes" onClick={() => onClick(row)} className={"clinic_filter__options__option"}>
                                        <label >
                                            <input className="clinic_filter__options__checkbox" type="checkbox" value={row.value} checked={row.checked || false} onChange={(e) => handleCheckboxChange(e, i, j)} />
                                            <span className="clinic_filter__options__checkbox__text">&nbsp;{row.value}</span>
                                        </label>
                                    </div>
                                </div>
                            })
                        }
                    </div>
                })
            }
        </div>
    }

    const renderTabs = () => {
        let options = getSelectedFilters();
        return <div>
            {
                options.map((tab, i) => {
                    return (tab.values.length > 0)
                        && <div key={"tab" + i} className="clinic_filter__tabs__tab">
                            <div>{`${filterTypesToStringMapper[tab.type]()}: ${tab.values[0] === true ? "Yes" : tab.values[0] === false ? "No" : truncateStr(tab.values[0], 20)}${tab.values.length > 1 ? ` +${tab.values.length - 1}` : ""}`}</div>
                            <svg viewBox="0 0 14 14" xmlns="http://www.w3.org/2000/svg" className="clinic_filter__tabs__tab__icon" onClick={() => handleFilterClearByType(tab.type)}>
                                <path d="M12.59 0L7 5.59L1.41 0L0 1.41L5.59 7L0 12.59L1.41 14L7 8.41L12.59 14L14 12.59L8.41 7L14 1.41L12.59 0Z" fill="white" />
                            </svg>
                        </div>
                })
            }
        </div>
    }

    return (
        <>
            <div className="clinic_filter"
                tabIndex="1"
                onBlur={(e) => handleClose(e)}>
                <div className="clinic_filter__content">
                    <div className="clinic_filter__text" onClick={() => handleOpen()} >
                        <FilterIcon />
                        <p className="p5">
                            Filters
                            {filterNumberOfChecked > 0
                                ? " (" + filterNumberOfChecked + ")"
                                : ""
                            }
                        </p>
                    </div>
                </div>
                {showPopup &&
                    <div className="clinic_filter__options">
                        {renderOptions(filterOptions)}
                    </div>
                }
            </div>
            {filterNumberOfChecked > 0 ?
                <div className="clinic_filter__tabs">
                    {renderTabs()}
                </div>
                : ""
            }
        </>
    );
}

export default PatientProductsFilter;

const filterTypesToStringMapper = {
    [clinicFilterTypes.productAvaiablity]: () => {
        return "Availability";
    },
    [clinicFilterTypes.productType]: () => {
        return "Type";
    },
    [clinicFilterTypes.irradiated]: () => {
        return "Irradiated";
    },
    [clinicFilterTypes.brandNames]: () => {
        return "Brand";
    },
}
