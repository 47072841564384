import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import AccountLayout from "../../../layouts/AccountLayout/AccountLayout";
import {
    getAllTerpenes, getAllSuppliersForPatient, getProductsForPatient, selectFromProductSliceByKey, selectPatientFilterNumberOfChecked, selectPatientProducts, selectPatientProductsLoadingFlag, selectPatientsFilteredProducts, getCurrentProductImagesForPatient, getCurrentProductDocsForPatient,
    selectProductImagesLoadingFlag, selectProductDocsLoadingFlag, selectCurrentProductImages, selectCurrentProductDocs, selectClinicTerpenes, selectClinicSuppliers, selectClinicTerpenesLoadingFlag, selectClinicSuppliersLoadingFlag
} from "../../../store/slices/productSlice";
import ReusableTable from "../../../components/sharedComponents/ReusableTable/ReusableTable";
import Spinner from "../../../components/sharedComponents/Spinner/Spinner";
import { ProductAvailability, ProductIrradiatedTypeMapper, ProductTypesMapper, TableHeaders, ProductSubtypesMapper, ProductPhEurMapper, ProductUnit, TerpeneOptions } from "../../../enums";
import { callMapper } from "../../../util/util";
import ReusableBadge from "../../../components/sharedComponents/ReusableBadge/ReusableBadge";
import HeaderTooltipContent from "../../../components/tooltips/tooltipContent/HeaderTooltipContent";
import ProductPreview from "../../../components/account/ProductPreview/ProductPreview";
import BackButtonIcon from "../../../assets/icons/BackButtonIcon";
import Spinner2 from "../../../components/sharedComponents/Spinner/Spinner2";
import ImageGallery from "react-image-gallery";
import { ReactComponent as Flower } from "../../../assets/images/FlowerTransparent.svg";
import PieChart from "../../../components/charts/PieChart";
import BarChart from "../../../components/charts/BarChart";
import CarbonDocumentIcon from "../../../assets/icons/CarbonDocumentIcon";


function PatientFormulary(props) {
    const dispatch = useDispatch();

    const products = useSelector(selectPatientProducts);
    const filteredProducts = useSelector(selectPatientsFilteredProducts);
    const patientFilterNumberOfChecked = useSelector(selectPatientFilterNumberOfChecked);


    const terpenes = useSelector(selectClinicTerpenes);
    const suppliers = useSelector(selectClinicSuppliers);

    const inputValue = useSelector(selectFromProductSliceByKey("patientSearchInputValue"));

    const loadingProducts = useSelector(selectPatientProductsLoadingFlag);
    const loadingTerpenes = useSelector(selectClinicTerpenesLoadingFlag);
    const loadingSuppliers = useSelector(selectClinicSuppliersLoadingFlag);

    const loadingProductImages = useSelector(selectProductImagesLoadingFlag);
    const loadingProductDocs = useSelector(selectProductDocsLoadingFlag);

    const currentProductImages = useSelector(selectCurrentProductImages);
    const currentProductDocs = useSelector(selectCurrentProductDocs);

    const [showPreview, setShowPreview] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [sortConfig, setSortConfig] = useState({ key: null, direction: 'ascending' });

    const [currentProduct, setCurrentProduct] = useState({});
    const [currentProductSupplier, setCurrentProductSupplier] = useState({});
    const [currentProductTerpeneType, setCurrentProductTerpeneType] = useState(1);


    const chartColors = [
        'rgb(147, 79, 57)',
        'rgb(198, 130, 108)',
        'rgb(230, 204, 160)',
        'rgb(99, 127, 96)',
        'rgb(160, 204, 154)',
        'rgb(178, 214, 191)',
        'rgb(51, 122, 183)',
        'rgb(61, 147, 221)',
        'rgb(119, 163, 201)',
    ];

    const [pieChartData, setPieChartData] = useState({
        labels: [],
        datasets: []
    });

    const [barChartData, setBarChartData] = useState({
        labels: [],
        datasets: []
    });

    const pieChartOptions = {
        plugins: {
            legend: {
                display: false,
            },
            tooltip: {
                callbacks: {
                    label(tooltipItems) {
                        return `${tooltipItems.label} ${tooltipItems.formattedValue}%`
                    }
                },
            },
        },
    };

    const barChartOptions = {
        plugins: {
            legend: {
                display: false,
            },
            tooltip: {
                callbacks: {
                    label(tooltipItems) {
                        return `${tooltipItems.label} ${tooltipItems.formattedValue}%`
                    }
                },
            },
        },
    };

    //Create the Zendesk support widget
    useEffect(() => {
        const script = document.createElement('script');
        script.id = 'ze-snippet';
        script.src = 'https://static.zdassets.com/ekr/snippet.js?key=5fd8aa31-21a7-4af7-9443-a1784d3f1c33';

        document.head.appendChild(script);

        return () => {
            document.head.removeChild(script);
        };
    }, []);

    useEffect(() => {
        if (!inputValue)
            dispatch(getProductsForPatient({ query: "" }))

        dispatch(getAllTerpenes())
        dispatch(getAllSuppliersForPatient())
        // eslint-disable-next-line
    }, [])

    const onRowClick = (e, product) => {
        e.stopPropagation()
        dispatch(getCurrentProductImagesForPatient({
            productId: product.id,
        }))
        dispatch(getCurrentProductDocsForPatient({
            productId: product.id,
        }))
        setCurrentProduct(product)
        setCurrentProductSupplier(suppliers.find((supplier) => supplier.id === product.supplierId))
        updatePieChartData(product)
        updateBarChartData(product)
        setShowPreview(true);
    }

    const requestSort = (key) => {
        let direction = 'ascending';
        if (sortConfig.key === key && sortConfig.direction === 'ascending') {
            direction = 'descending';
        }
        setSortConfig({ key, direction });
    }

    const sortedProducts = React.useMemo(() => {
        let sortableProducts = [...(patientFilterNumberOfChecked > 0 ? filteredProducts : products)];

        // Define the sorting order based on ProductAvailability enum
        const sortPriority = {
            [ProductAvailability.Available]: 0,
            [ProductAvailability.AvailableToOrder]: 1,
            [ProductAvailability.Unavailable]: 2,
            [ProductAvailability.Discontinued]: 3
        };

        if (sortConfig !== null) {
            sortableProducts.sort((a, b) => {
                // Check if the sort key is availability to apply custom sorting rules
                if (sortConfig.key === 'availability') {
                    const aPriority = sortPriority[a.availability];
                    const bPriority = sortPriority[b.availability];

                    return (
                        sortConfig.direction === 'ascending'
                            ? aPriority - bPriority
                            : bPriority - aPriority
                    );
                }

                // Handle other fields like 'thc' or 'cbd'
                const aValue = (['thc', 'cbd'].includes(sortConfig.key))
                    ? Math.abs(a[callMapper(sortConfigKeyMapper, sortConfig.key)])
                    : a[callMapper(sortConfigKeyMapper, sortConfig.key)];

                const bValue = (['thc', 'cbd'].includes(sortConfig.key))
                    ? Math.abs(b[callMapper(sortConfigKeyMapper, sortConfig.key)])
                    : b[callMapper(sortConfigKeyMapper, sortConfig.key)];

                if (aValue < bValue) {
                    return sortConfig.direction === 'ascending' ? -1 : 1;
                }
                if (aValue > bValue) {
                    return sortConfig.direction === 'ascending' ? 1 : -1;
                }
                return 0;
            });
        }

        return sortableProducts;
    }, [products, filteredProducts, sortConfig, patientFilterNumberOfChecked]);

    const updatePieChartData = (product) => {
        setCurrentProductTerpeneType(product.productTerpeneProfiles[0]?.type)
        const pieChartData = product.productTerpeneProfiles
            .filter(profile => terpenes.some(terpene => terpene.id === profile.terpeneId))
            .map(profile => {
                const matchingItem = terpenes.find(terpene => terpene.id === profile.terpeneId);
                return {
                    name: matchingItem.name,
                    value: profile.value,
                    type: resolveTerpeneType(profile)
                }
            })

        setPieChartData(
            {
                labels: pieChartData.map((e) => e.name),
                datasets: [
                    {
                        data: pieChartData.map((e) => e.value),
                        backgroundColor: chartColors,
                        hoverOffset: 4
                    }
                ]
            }
        )
    }

    const updateBarChartData = (product) => {
        const barChartData = [
            {
                name: "THC",
                value: Math.abs(product.thc)
            },
            {
                name: "CBD",
                value: Math.abs(product.cbd)
            }]
        setBarChartData(
            {
                labels: barChartData.map((e) => e.name),
                datasets: [
                    {
                        data: barChartData.map((e) => e.value),
                        backgroundColor: chartColors,
                    }
                ]
            }
        )
    }

    const isRowActive = (product) => {
        return currentProduct?.id === product.id ? "active-row" : ""
    }

    const loadingComponent = () => {
        return <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <Spinner color={"#13ae7d"} />
            <span className='m-t-s'>LOADING PRODUCTS</span>
        </div>
    }

    const availabilityTooltipContent = () => {
        return <HeaderTooltipContent text={
            <>
                {
                    <>
                        {renderBadge({ availability: ProductAvailability.Available })}
                        <p className="p6">In stock and available for dispatch from Lyphe Dispensary.</p>
                        <br />

                        {renderBadge({ availability: ProductAvailability.AvailableToOrder })}
                        <p className="p6">Available to order into Lyphe Dispensary in 24-48 working hours.</p>
                        <br />

                        {renderBadge({ availability: ProductAvailability.Unavailable })}
                        <p className="p6">Not currently available to order. We are actively working to restock this product.</p>
                        <br />

                        {renderBadge({ availability: ProductAvailability.Discontinued })}
                        <p className="p6">The product is permanently out of stock and will no longer be available from our supplier.</p>
                    </>
                }
            </>
        } />
    }

    const irradiatedTooltipContent = () => {
        return <HeaderTooltipContent text={
            <>
                {
                    <>
                        <p className="p6"><b>Non-irradiated</b> means a material has not been exposed to any form of ionizing radiation, such as alpha, beta, or gamma radiation. Non-irradiated items remain in their natural state without the alterations that might occur due to exposure to ionising radiation.</p>
                        <br />
                        <p className="p6"><b>Beta irradiation</b> involves exposing a material or organism to beta particles, which are high-energy, high-speed electrons or positrons. These particles are emitted by certain radioactive substances during radioactive decay. Beta radiation has moderate penetration power and can be used for various purposes such as sterilisation, medical treatments, and material testing.</p>
                        <br />
                        <p className="p6"><b>Gamma irradiation</b> involves exposing a material to gamma rays, which are a form of electromagnetic radiation with very high energy. allowing them to pass through most materials, making them effective for sterilisation, medical treatment etc.</p>
                    </>
                }
            </>
        } />
    }

    const renderBadge = (product) => {
        const textAndClass = callMapper(ProductAvailabilityBadgeMapper, product.availability, { text: "/", className: "" })
        return <ReusableBadge className={textAndClass.className} text={textAndClass.text} />
    }

    const renderTableBody = (products) => {
        return products.map(product => {
            return [
                {
                    text: renderBadge(product),
                    column: 2,
                    onRowClick: (e) => { onRowClick(e, product) },
                    classNameForRow: `${isRowActive(product)} hoverable formulary`,

                },
                {
                    text: product.name,
                    column: 3,
                    onRowClick: (e) => { onRowClick(e, product) },
                    left: true
                },
                {
                    text: callMapper(ProductTypesMapper, product.type),
                    column: 1,
                    onRowClick: (e) => { onRowClick(e, product) },
                    left: true
                },
                {
                    text: callMapper(ProductIrradiatedTypeMapper, product.irradiatedType),
                    column: 2,
                    onRowClick: (e) => { onRowClick(e, product) },
                    left: true
                },
                {
                    text: `${product.thc < 0 ? "<" : ""}${Math.abs(product.thc)} %`,
                    column: 1,
                    onRowClick: (e) => { onRowClick(e, product) },
                    left: true
                },
                {
                    text: `${product.cbd < 0 ? "<" : ""}${Math.abs(product.cbd)} %`,
                    column: 1,
                    onRowClick: (e) => { onRowClick(e, product) },
                    left: true
                },
                {
                    text: product.retailPrice.toFixed(2) + ' £',
                    column: 2,
                    onRowClick: (e) => { onRowClick(e, product) },
                    left: true
                },
            ]
        })
    }

    const renderPreviewPageTerpenes = () => {
        if (Object.keys(currentProduct).length != 0) {
            return <div className="previewTerpene">
                {currentProduct.productTerpeneProfiles
                    .filter(profile => terpenes.some(terpene => terpene.id === profile.terpeneId))
                    .map((profile, index) => {
                        const matchingItem = terpenes.find(terpene => terpene.id === profile.terpeneId);
                        return <div className="previewTerpene__item" key={matchingItem.id}>
                            <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <circle cx="6" cy="6" r="6" fill={`${chartColors[index]}`} />
                            </svg>
                            {` ${matchingItem.name} `}
                            <div className="previewTerpene__value">
                                {resolveTerpeneType(profile)}
                            </div>
                        </div>;
                    })}
            </div>
        }
        return <div>No terpene data</div>
    }

    const renderPreviewPageMinorMajorTerpenes = () => {
        if (Object.keys(currentProduct).length != 0) {
            return <div className="previewTerpene">
                {currentProduct.productTerpeneProfiles
                    .filter(profile => terpenes.some(terpene => terpene.id === profile.terpeneId))
                    .map((profile, index) => {
                        const matchingItem = terpenes.find(terpene => terpene.id === profile.terpeneId);
                        return <div className="previewTerpene__item" key={matchingItem.id}>
                            {` ${matchingItem.name} `}
                            <div className="previewTerpene__value">
                                {resolveTerpeneType(profile)}
                            </div>
                        </div>;
                    })}
            </div>
        }
        return <div>No terpene data</div>
    }

    return (
        <AccountLayout fullWidth={true}>
            {props.children}
            <div className="clinic_prescriptions_dashboard__disclaimer-banner__red">
                <p>Alternative product requests can be made only for products with the same THC percentage or lower.
                    For products with a higher THC percentage, a consultation will be required*.
                    Thank you for your understanding.</p>
            </div>
            {!showPreview && (
                <ReusableTable
                    loadingComponent={{ component: loadingComponent }}
                    loadingData={loadingProducts || loadingTerpenes || loadingSuppliers}
                    body={renderTableBody(sortedProducts)}
                    header={callMapper(TableHeaders, "patientProducts")()}
                    contentForTheTooltip={[availabilityTooltipContent(), irradiatedTooltipContent()]} //tooltips should be passed as an array
                    onPageChange={setCurrentPage}
                    currentPage={currentPage}
                    isSearchActive={patientFilterNumberOfChecked > 0}

                    requestSort={requestSort}
                    sortConfig={sortConfig}
                />
            )}
            {showPreview && <ProductPreview onClick={(e) => e.stopPropagation()} show={showPreview} shouldAutoResize={false}>
                <div className={"product_preview_content"}>
                    <div className="product_preview_content__header" >
                        <div style={{ cursor: "pointer" }} onClick={() => {
                            setShowPreview(!showPreview)
                            setCurrentProduct({})
                        }} >
                            <BackButtonIcon />
                        </div>
                    </div>
                    <div className="product_preview_content__headerRow">
                        {currentProduct.name || "N/A"}{renderBadge(currentProduct)}
                        <div className="product_preview_content__headerRow__right">
                        </div>
                    </div>
                    <div className={"product_preview_content__informations"}>
                        <div className="product_preview_content__informations__gridContainer">
                            <div className="div1">
                                {loadingProductImages ?
                                    <div className='spinner_loader'>
                                        <Spinner2 color={"#00a372"} width={"60"} />
                                    </div> :
                                    currentProductImages.length > 0 ?
                                        <ImageGallery items={
                                            currentProductImages.map((image) => (
                                                {
                                                    original: image.mediaUrl
                                                }))}
                                            showFullscreenButton={false}
                                            showPlayButton={false}
                                            showBullets={currentProductImages.length > 1}
                                            showNav={true}
                                        >
                                        </ImageGallery> :
                                        <div className='noPhotos'>
                                            <Flower />
                                            PRODUCT PHOTO WILL BE ADDED SOON
                                        </div>
                                }
                            </div>
                            <div className="div2">
                                <div className="product_preview_content__informations__gridContainer__gridElementHeader">
                                    Details
                                </div>
                                <div className="product_preview_content__informations__gridContainer__gridElementContent">
                                    <div className="productPreviewDetailsRow">
                                        <div>
                                            TYPE
                                            <div className="productPreviewDetailsRow__text">
                                                {callMapper(ProductTypesMapper, currentProduct.type)}
                                            </div>
                                        </div>
                                        <div>
                                            SUBTYPE
                                            <div className="productPreviewDetailsRow__text">
                                                {callMapper(ProductSubtypesMapper, currentProduct.subtype)}
                                            </div>
                                        </div>
                                        <div>
                                            Ph. Euro
                                            <div className="productPreviewDetailsRow__text">
                                                {callMapper(ProductPhEurMapper, currentProduct.phEur)}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="productPreviewDetailsRow">
                                        <div>
                                            THC
                                            <div className="productPreviewDetailsRow__text">
                                                {`${currentProduct.thc < 0 ? "<" : ""}${Math.abs(currentProduct.thc)}mg/ml`}
                                            </div>
                                        </div>
                                        <div>
                                            CBD
                                            <div className="productPreviewDetailsRow__text">
                                                {`${currentProduct.cbd < 0 ? "<" : ""}${Math.abs(currentProduct.cbd)}mg/ml`}
                                            </div>
                                        </div>
                                        <div>
                                            Irradiated
                                            <div className="productPreviewDetailsRow__text">
                                                {callMapper(ProductIrradiatedTypeMapper, currentProduct.irradiatedType)}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="productPreviewDetailsRow">
                                        <div>
                                            RETIAL PRICE
                                            <div className="productPreviewDetailsRow__text">
                                                {`£${currentProduct.retailPrice}`}
                                            </div>
                                        </div>
                                        <div>
                                            T21
                                            <div className="productPreviewDetailsRow__text">
                                                {`${currentProduct.isT21Eligible ? "Yes" : "No"}`}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="productPreviewDetailsRow">
                                        <div>
                                            SUPPLIER
                                            <div className="productPreviewDetailsRow__text">
                                                {`${currentProductSupplier ? currentProductSupplier.name : "/"}`}
                                            </div>
                                        </div>
                                        <div>
                                            MANUFACTURER
                                            <div className="productPreviewDetailsRow__text">
                                                {`${currentProduct.manufacturer ? currentProduct.manufacturer : "/"}`}
                                            </div>
                                        </div>
                                        <div>
                                            COUNTRY
                                            <div className="productPreviewDetailsRow__text">
                                                {`${currentProduct.country ? currentProduct.country : "/"}`}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="div3">
                                <div className="product_preview_content__informations__gridContainer__gridElementHeader">
                                    Terpene Profile
                                </div>
                                <div className="product_preview_content__informations__gridContainer__gridElementContent">
                                    {!currentProductTerpeneType &&
                                        <>
                                            <PieChart chartData={pieChartData} chartOptions={pieChartOptions} />
                                            {renderPreviewPageTerpenes()}
                                        </> ||
                                        renderPreviewPageMinorMajorTerpenes()
                                    }
                                    {!currentProduct.productTerpeneProfiles?.length &&
                                        <div className='noTerpenes'>
                                            No terpene profile added yet
                                        </div>}
                                </div>
                                <div className="product_preview_content__informations__gridContainer__gridElementHeader">
                                    Active ingredients
                                </div>
                                <div className="product_preview_content__informations__gridContainer__gridElementContent">
                                    <>
                                        <BarChart chartData={barChartData} chartOptions={barChartOptions} />
                                    </>
                                </div>
                            </div>

                            <div className="div4">
                                <div className="product_preview_content__informations__gridContainer__gridElementHeader">
                                    Documentation
                                </div>
                                <div className="product_preview_content__informations__gridContainer__gridElementContent">
                                    <div className="productPreviewDetailsRow">
                                        {loadingProductDocs ?
                                            <div className='spinner_loader'>
                                                <Spinner2 color={"#00a372"} width={"60"} />
                                            </div> :
                                            currentProductDocs.length > 0 ?
                                                currentProductDocs.map(doc => {
                                                    return <div key={doc.id} className="productPreviewDetailsRow__document" >
                                                        <CarbonDocumentIcon />
                                                        <a href={doc.mediaUrl} download target="_blank">{doc.mediaTitle}</a>
                                                    </div>
                                                }) :
                                                <div className='noDocs'>
                                                    No documenation available
                                                </div>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </ProductPreview>}
            <div className="clinic_prescriptions_dashboard__disclaimer-banner__transparent">
                <p>*Please be aware that some products may not be suitable for an alternative swap, even if they have the same THC levels.
                    This is often due to differences in whether the product is irradiated or non-irradiated.
                    In that rare occurrence, our clinicians will review your request and contact you if this affects your repeat prescription.</p>
            </div>
        </AccountLayout>
    )
}

const resolveTerpeneType = (profile) => {
    if (profile.type === TerpeneOptions.MAJORMINOR)
        return profile.value === 0 ? "Minor" : "Major"
    else if (profile.type === TerpeneOptions.PERCENTAGE)
        return `${profile.value}%`
    else
        return ""
}

const ProductAvailabilityBadgeMapper = {
    [ProductAvailability.Available]: {
        text: "AVAILABLE",
        className: "reusable_badge--greenProduct"
    },
    [ProductAvailability.AvailableToOrder]: {
        text: "AVAILABLE TO ORDER",
        className: "reusable_badge--yellowProduct"
    },
    [ProductAvailability.Unavailable]: {
        text: "UNAVAILABLE",
        className: "reusable_badge--redProduct"
    },
    [ProductAvailability.Discontinued]: {
        text: "DISCONTINUED",
        className: "reusable_badge--greyProduct"
    },
    undefined: {
        text: "/",
        className: ""
    },
}


const sortConfigKeyMapper = {
    "availability": "availability",
    "name": "name",
    "type": "type",
    "total price": "retailPrice",
    "thc": "thc",
    "cbd": "cbd",
    "irradiated": "irradiatedType",
}

export default PatientFormulary;