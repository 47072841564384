import React from "react";
import { Switch, Redirect, Route } from "react-router-dom";

import {
  Profile,
  IdVerification,
  Support,
  EmailNotVerified,
  EmailVerified,
  VerificationEmailResent,
  ChangePassword,
  BookAConsultation,
  ReportProblem,
  ActivePrescriptions,
  PastPrescriptions, OrganisationGetStarted, OrganisationNoAccess,
} from "../pages/account";
import ProtectedRoute from "../routes/ProtectedRoute";
import PublicRoute from "../routes/PublicRoute";
import {
  Login,
  PasswordReset,
  PasswordResetFail,
  PasswordResetSuccess,
  RequestPasswordReset,
  RequestPasswordResetSuccess,
} from "../pages/authentication";

import { LogoutPopup, PayPopup, ResubmitPopup, ConsultationPopup, StartLiveChatPopup } from "../components/popups";
import { ResubmitLoadingOverlay } from "../components/overlays";

import {
  PatientCarerCompleteAccount,
  GetStarted,
  PatientCarerOnboarding
} from "../pages/onboarding";
import {
  PrescriberMobileUploadPrescription,
} from "../pages/account/uploadPrescription";
import { StandAloneEligibilityCriteria } from "../pages/stepperPages";
import AcceptCookiesPopup from "../components/popups/AcceptCookiesPopup/AcceptCookiesPopup";
import { useSelector } from "react-redux";
import { selectUserDetails } from "../store/slices/accountSlice";
import ProtectedPatientRoute from "../routes/ProtectedPatientRoute";
import ProtectedDoctorRoute from "../routes/ProtectedDoctorRoute";
import AllPrescriptionsDoctor from "../pages/account/AllPrescriptionsDoctor/AllPerscriptionsDoctor";
import SingleOrder from "../pages/account/SingleOrder/SingleOrder";
import { usePageTracking } from "../hooks";
import OrderPayment from "../pages/account/OrderPayment/OrderPayment";
import GenericSuccessPopup from "../components/popups/GenericSuccessPopup/GenericSuccessPopup";
import Toast from "../components/Toast/Toast";
import { selectToast } from "../store/slices/toastSlice";
import ReadyForPaymentPopup from "../components/popups/ReadyForPaymentPopup/ReadyForPaymentPopup";
import CallBackRequested from "../pages/account/emailVerification/CallBackRequested";
import PaymentLinkRequested from "../pages/account/emailVerification/PaymentLinkRequested";
import SecretExpired from "../pages/authentication/secretExpired/SecretExpired";
import PrescriptionExpired from "../pages/account/PrescriptionExpired/PrescriptionExpired";
import OrderCancelled from "../pages/account/OrderCancelled/OrderCancelled";
import OrganisationsPrescriptions from "../pages/account/ClinicsPages/ClinicsPrescriptions/OrganisationsPrescriptions";
import ProtectedClinicUserRoute from "../routes/ProtectedClinicUserRoute";
import ClinicPatients from "../pages/account/ClinicsPages/ClinicPatients/ClinicPatients";
import repeatPrescription from "../pages/repeatPrescription/RepeatPrescription";
import repeatPrescriptionPage from "../pages/repeatPrescription/RepeatPrescriptionPage";
import ClinicFormulary from "../pages/account/ClinicsPages/ClinicFormulary/ClinicFormulary";
import patientFormulary from "../pages/account/PatientFormulary/PatientFormulary";

function MainRouter() {
  usePageTracking();

  const user = useSelector(selectUserDetails);
  const toast = useSelector(selectToast);
  return (
    <>
      <LogoutPopup />
      <PayPopup />
      <ReadyForPaymentPopup />
      <ResubmitPopup />
      <ResubmitLoadingOverlay />
      <ConsultationPopup />
      <GenericSuccessPopup />
      {toast.showToast && <Toast />}

      <StartLiveChatPopup />

      {user?.userId ? <AcceptCookiesPopup /> : ''}

      <Switch>
        <ProtectedPatientRoute
          path="/prescriptions/active"
          component={ActivePrescriptions}
        />

        <ProtectedDoctorRoute
          path="/prescriptions/all"
          component={AllPrescriptionsDoctor}
        />

        <ProtectedClinicUserRoute path="/clinicPrescriptions/:status?" component={OrganisationsPrescriptions} />
        <ProtectedClinicUserRoute path="/clinicPatients/:status?" component={ClinicPatients} />
        <ProtectedClinicUserRoute path="/clinicFormulary/:status?" component={ClinicFormulary} />
        <ProtectedClinicUserRoute path="/organisation/getstarted" component={OrganisationGetStarted} />
        <ProtectedClinicUserRoute path="/organisation/noaccess" component={OrganisationNoAccess} />

        <ProtectedDoctorRoute
          path="/order/:orderId"
          exact
          component={SingleOrder}
        />

        <PublicRoute
          path="/order/payment/:orderNumber"
          component={OrderPayment}
        />

        <ProtectedPatientRoute
          path="/order/onboardedpayment/:orderNumber"
          component={OrderPayment}
        />

        <ProtectedPatientRoute
          path="/prescriptions/past"
          component={PastPrescriptions}
        />

        <ProtectedPatientRoute
          path="/patientFormulary"
          component={patientFormulary}
        />

        <ProtectedRoute path="/profile" component={Profile} />

        <ProtectedRoute path="/changePassword" component={ChangePassword} />

        <ProtectedRoute
          path="/upload/prescriber"
          component={PrescriberMobileUploadPrescription}
        />

        <ProtectedRoute path="/consultation" component={BookAConsultation} />

        <ProtectedRoute path="/support" component={Support} />

        <ProtectedRoute path="/report/:orderId" component={ReportProblem} />

        <ProtectedRoute path="/repeatPrescription" component={repeatPrescription} />

        <ProtectedRoute path="/repeatPrescriptionForm" component={repeatPrescriptionPage} />

        <ProtectedRoute
          path="/verification"
          checkId={false}
          component={IdVerification}
        />

        <PublicRoute path="/auth/login" component={Login} />

        <PublicRoute
          exact
          path="/auth/resetPassword"
          component={RequestPasswordReset}
        />

        <PublicRoute
          path="/auth/resetPassword/emailSent"
          component={RequestPasswordResetSuccess}
        />

        <PublicRoute
          path="/auth/resetPassword/fail"
          component={PasswordResetFail}
        />

        <PublicRoute
          path="/auth/resetPassword/success"
          component={PasswordResetSuccess}
        />

        <PublicRoute
          path="/resetpassword/:secretId"
          component={PasswordReset}
        />

        <Route path="/eligibility" component={StandAloneEligibilityCriteria} />

        <Route exact path="/onboarding" component={PatientCarerOnboarding} >
          <Redirect to="/splash" />
        </Route>

        <Route path="/getstarted" component={GetStarted} >
          <Redirect to="/splash" />
        </Route>
        <Route path="/getstarted" component={GetStarted} />

        <Route
          path="/onboarding/:secretId"
          component={PatientCarerCompleteAccount}
        />

        <Route path="/verify/:secretId" component={EmailVerified} />

        <ProtectedRoute
          checkEmail={false}
          checkId={false}
          path="/emailnotverified"
          component={EmailNotVerified}
        />

        <ProtectedRoute
          checkEmail={false}
          checkId={false}
          path="/verificationresent"
          component={VerificationEmailResent}
        />

        <Route
          path="/paymentlinkrequested"
          component={PaymentLinkRequested}
        />

        <Route
          path="/linkexpired"
          component={SecretExpired}
        />

        <Route
          path="/callbackrequested"
          component={CallBackRequested}
        />

        <Route
          path="/prescriptionexpired"
          component={PrescriptionExpired}
        />

        <Route
          path="/ordercancelled"
          component={OrderCancelled}
        />

        <Redirect to="/auth/login" />
      </Switch>
    </>
  );
}

export default MainRouter;
