import React from "react";
import { PrimaryCta } from "../../../components/buttons";
import WhatHappensNextCard from "../../../components/cards/WhatHappensNextCard/WhatHappensNextCard";
import { TipsCarousel } from "../../../components/carousels";
import { AfterRegistrationTipsWithPrescription } from "../../../enums";

function CheckYourEmail() {
  return (
    <section className="check_your_email">
      <div className="check_your_email__content">
        <div className="check_your_email__title">
          <p className="label--caps--normal">Next steps</p>
          <h1 className="h3-a">Please check your email</h1>
          <p className="p5">
            Thanks for confirming your prescription, our team is now processing
            it! In the meantime, please open the link we have emailed you to
            verify your account.
          </p>
        </div>

        <div className="check_your_email__cards">
          <div className="check_your_email__cards__title">
            <p className="h8-a">What happens next?</p>
          </div>
          <div className="check_your_email__cards__text">
            {AfterRegistrationTipsWithPrescription.map((step, index) => (
              <div key={index}>
                <WhatHappensNextCard {...step} />
              </div>
            ))}
          </div>
        </div>

        <div className="check_your_email__cards--mobile">
          <TipsCarousel tips={AfterRegistrationTipsWithPrescription} />
        </div>

        <div className="check_your_email__buttons">
          <div>
            <PrimaryCta color="orange" text="Learn more" link="/" />
          </div>
        </div>
      </div>
    </section>
  );
}

export default CheckYourEmail;
