import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { patientService } from "../../services/PatientService";
import { paymentService } from "../../services/PaymentService";
import { prescriptionService } from "../../services/PrescriptionServices";

export const getCurrentClinicDetails = createAsyncThunk(
    "clinics/GetCurrentClinicDetails",

    async ({ userId }) => {
        const curentClinic = await prescriptionService.getCurrentClinicDetails(userId);

        return {
            curentClinic
        };
    }
);

export const getAllByPatientsId = createAsyncThunk(
    "products/getAllByPatientsId",

    async (patientId) => {
        const response = await patientService.getAllByPatientsId(patientId);

        return response;
    }
);

export const getRepeatPrescriptionPaymentIntent = createAsyncThunk(
    "transactions/GetRepeatPrescriptionPaymentIntent",

    async (patientId) => {
        const response = await paymentService.getRepeatPrescriptionPaymentIntent(patientId)

        return response;
    }
);

export const requestRepeatPrescription = createAsyncThunk(
    "prescriptions/RequestRepeatPrescription",

    async (payload) => {
        await prescriptionService.requestRepeatPrescription(payload)
    }
);

const initialState = {
    showRepeatPrescriptionFormGuideline: true,
    showRepeatPrescriptionForm: false,
    showRepeatPrescriptionFormCompleted: false,
    showRepeatPrescriptionNoEligibleProductsPage: false,
    submitFormLoader: false,
    curentClinicDetails: {
        telephoneNo: "",
        name: "",
        email: "",
        address1: "",
    },
    patientsProducts: {},

    paymentIntent: {
        id: "",
        clientSecret: ""
    },

    patientAnswers: {
        question1: "",
        question2: "",
        question3: "",
        question4: "",
    },
};

const repeatPrescriptionSlice = createSlice({
    name: "repeatPrescription",
    initialState,

    // sync reducers
    reducers: {
        setSubmitFormLoader(state, paylaod) {
            state.submitFormLoader = paylaod
        },

        setShowRepeatPrescriptionFormGuideline(state) {
            state.showRepeatPrescriptionFormGuideline = true;
            state.showRepeatPrescriptionFormCompleted = false;
            state.showRepeatPrescriptionForm = false;
            state.showRepeatPrescriptionNoEligibleProductsPage = false;
        },

        setShowRepeatPrescriptionForm(state, { payload }) {
            state.showRepeatPrescriptionForm = true;
            state.showRepeatPrescriptionFormGuideline = false;
            state.showRepeatPrescriptionFormCompleted = false;
            state.showRepeatPrescriptionNoEligibleProductsPage = false;
            state.patientAnswers = payload;
        },

        setShowRepeatPrescriptionFormCompleted(state) {
            state.showRepeatPrescriptionFormCompleted = true;
            state.showRepeatPrescriptionForm = false;
            state.showRepeatPrescriptionFormGuideline = false;
            state.showRepeatPrescriptionNoEligibleProductsPage = false;
        },

        setShowRepeatPrescriptionNoEligibleProductsPage(state) {
            state.showRepeatPrescriptionNoEligibleProductsPage = true;
            state.showRepeatPrescriptionFormCompleted = false;
            state.showRepeatPrescriptionForm = false;
            state.showRepeatPrescriptionFormGuideline = false;
        },

    },

    extraReducers: {
        //getCurrentClinicDetails

        [getCurrentClinicDetails.pending]: (state) => { },
        [getCurrentClinicDetails.fulfilled]: (state, { payload }) => {
            state.curentClinicDetails = payload.curentClinic
        },
        [getCurrentClinicDetails.rejected]: (state) => { },

        //getAllByPatientsId

        [getAllByPatientsId.pending]: (state) => { },
        [getAllByPatientsId.fulfilled]: (state, { payload }) => {
            state.patientsProducts = payload
        },
        [getAllByPatientsId.rejected]: (state) => { },

        //transactions/GetRepeatPrescriptionPaymentIntent

        [getRepeatPrescriptionPaymentIntent.pending]: (state) => { },
        [getRepeatPrescriptionPaymentIntent.fulfilled]: (state, { payload }) => {
            state.paymentIntent = payload
        },
        [getRepeatPrescriptionPaymentIntent.rejected]: (state) => { },

        //prescriptions/RequestRepeatPrescription
        [requestRepeatPrescription.pending]: (state) => { },
        [requestRepeatPrescription.fulfilled]: (state) => {
            state.submitFormLoader = false
        },
        [requestRepeatPrescription.rejected]: (state) => { },
    },
});

export default repeatPrescriptionSlice.reducer;

// export sync actions
export const {
    setShowRepeatPrescriptionForm,
    setShowRepeatPrescriptionFormGuideline,
    setShowRepeatPrescriptionFormCompleted,
    setShowRepeatPrescriptionNoEligibleProductsPage,
    setSubmitFormLoader,

} = repeatPrescriptionSlice.actions;



export const selectShowRepeatPrescriptionForm = (state) => state.repeatPrescription.showRepeatPrescriptionForm;

export const selectShowRepeatPrescriptionFormGuideline = (state) => state.repeatPrescription.showRepeatPrescriptionFormGuideline;

export const selectShowRepeatPrescriptionFormCompleted = (state) => state.repeatPrescription.showRepeatPrescriptionFormCompleted;

export const selectCurentClinicDetails = (state) => state.repeatPrescription.curentClinicDetails;

export const selectPatientProducts = (state) => state.repeatPrescription.patientsProducts;

export const selectClientSecret = (state) => state.repeatPrescription.paymentIntent.clientSecret;

export const selectPaymentIntnetId = (state) => state.repeatPrescription.paymentIntent.id;

export const selectSubmitFormLoader = (state) => state.repeatPrescription.submitFormLoader;

export const selectShowRepeatPrescriptionNoEligibleProductsPage = (state) => state.repeatPrescription.showRepeatPrescriptionNoEligibleProductsPage;

export const selectPatientAnswers = (state) => state.repeatPrescription.patientAnswers;