import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import LypheLogo from '../../../assets/icons/LypheLogo';

import RepeatPrescriptionLoaderPage from '../../../pages/repeatPrescription/RepeatPrescriptionLoaderPage'
import { getPatientDetails, selectUserDetails, } from '../../../store/slices/accountSlice';
import {
    getAllByPatientsId,
    getRepeatPrescriptionPaymentIntent,
    requestRepeatPrescription,
    selectClientSecret,
    selectPatientProducts,
    selectPaymentIntnetId,
    selectShowRepeatPrescriptionForm,
    selectShowRepeatPrescriptionFormCompleted,
    selectShowRepeatPrescriptionFormGuideline,
    selectSubmitFormLoader,
    setSubmitFormLoader,
    setShowRepeatPrescriptionFormCompleted,
    setShowRepeatPrescriptionFormGuideline,
    selectShowRepeatPrescriptionNoEligibleProductsPage,
    setShowRepeatPrescriptionNoEligibleProductsPage,
    selectPatientAnswers
} from '../../../store/slices/repeatPrescriptionSlice';
import { PrimaryCta } from '../../buttons';
import RepeatPrescriptionHeader from '../../headers/RepeatPrescriptionHeader/RepeatPrescriptionHeader';
import RepeatPrescriptionCompleted from './RepeatPrescriptionCompleted';
import RepeatPrescriptionFormGuideline from './RepeatPrescriptionFormGuideline';
import RepeatPrescriptionProductSelect from './RepeatPrescriptionProductSelect/RepeatPrescriptionProductSelect';

import RepatPrescriptionFormArrow from '../../../assets/icons/RepatPrescriptionFormArrow';
import RepeatPrescriptionFormInfoBannerIcon from '../../../assets/icons/RepeatPrescriptionFormInfoBannerIcon';
import RepeatPrescriptionErrorBanner from '../../banners/RepeatPrescriptionErrorBanner/RepeatPrescriptionErrorBanner';
import { selectUser } from '../../../store/slices/authenticationSlice';
import Spinner from '../../sharedComponents/Spinner/Spinner';
import RepeatPrescriptionNoEligibleProductsPage from './RepeatPrescriptionNoEligibleProductsPage';
import { SubscriptionTypes } from '../../../enums';
import RepeatPrescriptionInfoBanner from '../../banners/RepeatPrescriptionInfoBanner/RepeatPrescriptionInfoBanner';

export default function RepeatPrescriptionForm() {
    const elements = useElements()
    const stripe = useStripe();
    const dispatch = useDispatch();

    const userDetails = useSelector(
        selectUserDetails
    )

    const patientId = userDetails.id

    //State

    const [repeatPrescriptionProducts, setRepeatPrescriptionProducts] = useState([])
    const [comment, setComment] = useState('')
    const [loading, setLoading] = useState(true)
    const [cardholederName, setCardholederName] = useState('')

    const [showPaymentErrorBanner, setShowPaymentErrorBanner] = useState(false)
    const [showQntErrorBanner, setShowQntErrorBanner] = useState(false)
    const [showFeeInfoBanner, setShowFeeInfoBanner] = useState(false)

    useEffect(() => {
        const fetchData = async () => {
            await Promise.all([
                dispatch(getAllByPatientsId(patientId)),
                dispatch(getPatientDetails()),
                dispatch(getRepeatPrescriptionPaymentIntent(patientId))
            ]);

            setLoading(false);
        };

        fetchData();

        // eslint-disable-next-line
    }, []);

    const submitLoader = useSelector(
        selectSubmitFormLoader
    )

    const patientProducts = useSelector(
        selectPatientProducts
    );

    const showRepeatPrescriptionForm = useSelector(
        selectShowRepeatPrescriptionForm
    );

    const showRepeatPrescriptionFormCompleted = useSelector(
        selectShowRepeatPrescriptionFormCompleted
    );

    const showRepeatPrescriptionFormGuideline = useSelector(
        selectShowRepeatPrescriptionFormGuideline
    );

    const showRepeatPrescriptionNoEligibleProductsPage = useSelector(
        selectShowRepeatPrescriptionNoEligibleProductsPage
    );

    const hadnleBackButton = () => {
        dispatch(setShowRepeatPrescriptionFormGuideline(true))
    }

    const CLIENT_SECRET = useSelector(
        selectClientSecret
    );

    const stripeTransactionId = useSelector(
        selectPaymentIntnetId
    );

    const userInfo = useSelector(
        selectUser
    );

    const patientAnswers = useSelector(
        selectPatientAnswers
    );

    const CARD_ELEMENT_OPTIONS = {
        hidePostalCode: true,
        style: {
            base: {
                border: "1px solid black",
                color: "#32325d",
                fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
                fontSmoothing: "antialiased",
                fontSize: "18px",
                "::placeholder": {
                    color: "#aab7c4",
                },
            },
            invalid: {
                color: "#fa755a",
                iconColor: "#fa755a",
            },
        },
    };

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [showRepeatPrescriptionNoEligibleProductsPage, showRepeatPrescriptionFormGuideline, showRepeatPrescriptionFormCompleted])

    useEffect(() => {
        const productWithZeroQnt = repeatPrescriptionProducts.find(product => product.quantity === 0)
        productWithZeroQnt ? setShowQntErrorBanner(true) : setShowQntErrorBanner(false)
        userDetails.subscriptionType !== SubscriptionTypes.None ? setShowFeeInfoBanner(true) : setShowFeeInfoBanner(false)

    }, [repeatPrescriptionProducts])


    useEffect(() => {
        if (patientProducts.length === 0) {
            dispatch(setShowRepeatPrescriptionNoEligibleProductsPage(true))
        }
    }, [patientProducts])

    //Handle change quantity for each individual product in patient products
    const handleChange = (value, id, name) => {
        let patientProduct = repeatPrescriptionProducts.length > 0 && repeatPrescriptionProducts.find((product) => product.id == id)
        const patientProductIndex = repeatPrescriptionProducts.length > 0 && repeatPrescriptionProducts.findIndex((product) => product.id == id)
        if (patientProduct) {
            patientProduct = { ...patientProduct, quantity: value }
            let currentProducts = [...repeatPrescriptionProducts]
            currentProducts.splice(patientProductIndex, 1, patientProduct)
            setRepeatPrescriptionProducts(currentProducts)
        } else {
            const newProduct = {
                "name": name,
                "quantity": value,
                "id": id
            }
            setRepeatPrescriptionProducts([...repeatPrescriptionProducts, newProduct])
        }
    };

    const handleChecked = (value, id, name) => {
        let patientProduct = repeatPrescriptionProducts.length > 0 && repeatPrescriptionProducts.find((product) => product.id == id)
        const patientProductIndex = repeatPrescriptionProducts.length > 0 && repeatPrescriptionProducts.findIndex((product) => product.id == id)

        if (patientProduct && value === false) {
            let currentProducts = [...repeatPrescriptionProducts]
            currentProducts.splice(patientProductIndex, 1)
            setRepeatPrescriptionProducts(currentProducts)

            return
        }

        if (!patientProduct && value === true) {
            const newProduct = {
                "name": name,
                "quantity": 0,
                "id": id
            }
            setRepeatPrescriptionProducts([...repeatPrescriptionProducts, newProduct])
        }
    }

    const handleSubmit = async () => {
        dispatch(setSubmitFormLoader(true))

        //Only patients that arent subscribed are charged for repeats
        if (userDetails.subscriptionType === SubscriptionTypes.None) {
            const result = await stripe.confirmCardPayment(CLIENT_SECRET, {
                payment_method: {
                    card: elements.getElement(CardElement),
                    billing_details: {
                        name: cardholederName,
                    },
                }
            });

            if (result.error) {
                console.log(result.error.message);
                setShowPaymentErrorBanner(true)
                dispatch(setSubmitFormLoader(false))

            } else {
                if (result.paymentIntent.status === 'succeeded') {
                    dispatch(requestRepeatPrescription({ products: repeatPrescriptionProducts, comment, stripeTransactionId, patientId: patientId, patientAnswers }));
                    dispatch(setShowRepeatPrescriptionFormCompleted(true));
                }
            }
        } else {
            dispatch(requestRepeatPrescription({ products: repeatPrescriptionProducts, comment, stripeTransactionId: "No charge", patientId: patientId, patientAnswers }))
            dispatch(setShowRepeatPrescriptionFormCompleted(true));
        }
    };

    return (
        <>
            {!loading ?
                <div className='repeat_prescription_form'>
                    <RepeatPrescriptionHeader showNativeBrowserWarning={showRepeatPrescriptionForm} />
                    <div className='Lyphe_logo'>
                        <LypheLogo />
                    </div>
                    {showRepeatPrescriptionForm && <div onClick={hadnleBackButton} className='back-to-previous-button'>
                        <RepatPrescriptionFormArrow />
                        <p>Back to previous</p>
                    </div>}
                    <div className='repeat_prescription_form--content'>
                        {showRepeatPrescriptionFormCompleted && <RepeatPrescriptionCompleted />}
                        {showRepeatPrescriptionFormGuideline && <RepeatPrescriptionFormGuideline />}
                        {showRepeatPrescriptionNoEligibleProductsPage && <RepeatPrescriptionNoEligibleProductsPage />}

                        {showRepeatPrescriptionForm && <div className='form'>
                            <div className='form--heading'>
                                <h1>Request a repeat prescription</h1>
                                <div className='divider'></div>
                            </div>
                            <div className='form--top-content'>
                                <div className='form--top-content--heading'>
                                    <h2><span>1</span>Your details</h2>
                                </div>
                                <div className='your_details'>
                                    <span>Patient Name</span>
                                    <p>{userDetails.firstName + " " + userDetails.lastName}</p>
                                    <span>Email</span>
                                    <p>{userInfo.email}</p>
                                    <span>Patient ID</span>
                                    <p>{userDetails.clinicIdNumber}</p>
                                </div>
                                <div className='form--top-content--heading'>
                                    <h2><span>2</span>Your medication</h2>
                                </div>
                                <div className='your_medication'>
                                    <p>Select the medication and the quantity you would like to request.</p>
                                    <div className='info-banner'>
                                        <RepeatPrescriptionFormInfoBannerIcon />
                                        <p>Please make sure you are requesting the amount that you need. If you believe a product is missing that should be here, please
                                            <a className='info-banner-link' target="_blank" rel="noopener noreferrer" href="https://lyphe.com/"> contact us</a> via Live Chat.
                                        </p>
                                    </div>
                                    {patientProducts.map(product => {
                                        return (
                                            <RepeatPrescriptionProductSelect
                                                name={product.name}
                                                weight={product.weight}
                                                handleChange={(value) => handleChange(value, product.id, product.name)}
                                                handleChecked={(value) => handleChecked(value, product.id, product.name)} />
                                        )
                                    })}
                                    <p><b>Where medication is unavailable, you will be prescribed a suitable alternative.</b></p>
                                    <div className='comment-section'>
                                        <span>Comment (optional)</span>
                                        <p>Any additional information you would like us to know.</p>
                                        <textarea value={comment} onChange={(event) => setComment(event.target.value)} placeholder="Your comment..." />
                                    </div>
                                </div>
                                {userDetails.subscriptionType === SubscriptionTypes.None && <>
                                    <div className='form--top-content--heading'>
                                        <h2><span>3</span>Your card details</h2>
                                    </div>
                                    <div className='repeat_prescription_fee-banner'>
                                        <p>Repeat prescription fee</p>
                                        <p>£25</p>
                                    </div>
                                    <div className='card_details'>
                                        <p>Cardholder name</p>
                                        <input type='text' placeholder='Name' value={cardholederName} onChange={(event) => setCardholederName(event.target.value)} />
                                        <p>Card number</p>
                                        <div className='stripe_card'>
                                            <CardElement options={CARD_ELEMENT_OPTIONS} />
                                        </div>
                                    </div></>}
                                <div className='request_section'>
                                    <h2>Your request</h2>
                                    {repeatPrescriptionProducts.map(requestedProduct => {
                                        return (
                                            <div className='request_section_info'>
                                                <p>{requestedProduct.name}</p>
                                                <p>{requestedProduct.quantity}</p>
                                            </div>
                                        )
                                    })}
                                </div>
                            </div>

                            {showFeeInfoBanner && <RepeatPrescriptionInfoBanner type={"info"} text={"As a valued subscription member, your repeat prescription fee is included as part of your membership."} />}
                            {showPaymentErrorBanner && userDetails.subscriptionType === SubscriptionTypes.None && <RepeatPrescriptionErrorBanner type={"error"} text={"Payment failed. Please try again"} />}
                            {showQntErrorBanner && <RepeatPrescriptionErrorBanner type={"error"} text={"Your request cannot be sent with a medication quantity set to 0. Uncheck box if the medication is not required."} />}
                            <div className='submit-button'>
                                <PrimaryCta
                                    color='form'
                                    className='primary_cta--form'
                                    onClick={handleSubmit}
                                    isEnabled={!showQntErrorBanner && !(repeatPrescriptionProducts.length == 0)}
                                >
                                    {submitLoader.payload ? <Spinner width={20} /> : "Submit"}
                                </PrimaryCta>
                            </div>
                            <p className='form_footer-p'>If you have any questions or issues, please contact <a href="mailto:info@lyphe.com">info@lyphe.com</a></p>
                        </div>}
                    </div>
                </div>
                :

                (<RepeatPrescriptionLoaderPage loading={(loading || Object.keys(patientProducts).length === 0)} />)
            }
        </>
    )
}
